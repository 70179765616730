














































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  GetBookMulu,
  GetBookMulu2,
  PostBookContent,
} from "@/request/storehouse";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private refreshTime: any;
  @Watch("refreshTime", { immediate: true })
  private refreshTimeChange() {
    this.getMulu();
  }
  private navs: any = [];
  private mulu: any = [];
  private currentNode: any = {};
  private defaultProps: any = {
    label: "章节名",
  };
  private expandedNode: any = [];
  private currentZhangjie: any = "";
  private detail: any = {};
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private handleNodeClick(data: any, node: any) {
    const parant: any = document.getElementById("scrollBox");
    const children: any = document.getElementById("part" + data._id);
    if (parant) {
      if (children) {
        const parentTop = parant.offsetTop;
        const childTop = children.offsetTop;
        parant.scrollTop = childTop - parentTop - 14;
      }
    }
    setTimeout(() => {
      this.currentNode = data;
      this.init();
    }, 50);
  }
  private getMulu() {
    const params: any = {
      params: {
        book_id: this.data.book_id,
      },
    };
    if (!this.data.book_id) {
      return;
    }
    GetBookMulu(this, params).then((res: any) => {
      console.log("2222");
      console.log(res);
      this.navs = res;
      if (this.currentNode._id) {
        this.handleNodeClick(this.currentNode, "");
      } else {
        this.currentNode = res.titles[0];
        this.init();
      }
    });
    GetBookMulu2(this, params).then((d: any) => {
      this.mulu = d;
      setTimeout(() => {
        this.refreshData();
      }, 200);
    });
  }
  private getData(id?: any) {
    const params: any = {
      params: {
        title_id: this.currentNode._id,
      },
    };
    if (id) {
      params.params.title_id = id;
    }
    this.mulu.forEach((ele: any) => {
      if (ele._id == params.params.title_id) {
        ele.status = "pendding";
        this.$forceUpdate();
      }
    });
    PostBookContent(this, params)
      .then((res: any) => {
        this.mulu.forEach((ele: any) => {
          if (ele._id == res.title_id) {
            ele.content = res.content;
            ele.status = "success";
            this.$forceUpdate();
          }
        });
      })
      .catch(() => {
        this.mulu.forEach((ele: any) => {
          if (ele._id == this.currentNode._id) {
            ele.status = "fail";
            this.$forceUpdate();
          }
        });
      });
  }
  private init() {
    if (this.$refs.tree) {
      setTimeout(() => {
        (this.$refs.tree as any).setCurrentKey(this.currentNode);
      }, 300);
      this.expandedNode = [this.currentNode["_id"]];
    }
  }
  private refreshData() {
    const parant: any = document.getElementById("scrollBox");
    const scrollTop = parant.scrollTop;
    const box = (this.$refs.content as any).offsetHeight;
    this.mulu.forEach((ele: any) => {
      const children: any = document.getElementById("part" + ele._id);
      const top = children.offsetTop;
      const num = top - scrollTop;
      if (num > -200 && num < box + 100) {
        if (ele.status != "pendding" && ele.status != "success") {
          this.getData(ele._id);
        }
      }
    });
  }
  private scrollEvent() {
    const parant = document.getElementById("scrollBox");
    if (parant) {
      const scrollTop = parant.scrollTop;
      const parentTop = parant.offsetTop + 14;
      this.mulu.forEach((ele: any, i: any) => {
        if (i < this.mulu.length - 1) {
          const cuEle = document.getElementById("part" + ele._id);
          const nextEle = document.getElementById(
            "part" + this.mulu[i + 1]._id
          );
          if (cuEle && nextEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            const nextTop = nextEle.offsetTop - parentTop;
            if (scrollTop >= cuTop && scrollTop < nextTop) {
              this.currentNode = ele;
              this.init();
            }
          }
        } else {
          const cuEle = document.getElementById("part" + ele._id);
          if (cuEle) {
            const cuTop = cuEle.offsetTop - parentTop;
            if (scrollTop >= cuTop) {
              this.currentNode = ele;
              this.init();
            }
          }
        }
        // 视野范围内的都需要获取章节信息
        this.refreshData();
      });
    }
  }
  private mounted() {
    this.currentNode = {
      _id: this.$route.query.id,
    };
  }
}
